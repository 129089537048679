import { useState, useEffect } from 'react'
import { getContrastColor } from '../utils/colorUtils'
import debug from '../utils/debug'

export const useContrastColor = (
  backgroundColor: string,
  intendedColor: string,
  lightColor = '#FFFFFF',
  darkColor = '#000000'
) => {
  const [contrastColor, setContrastColor] = useState('#000000') // Default to dark color

  useEffect(() => {
    if (backgroundColor) {
      const newColor = getContrastColor(backgroundColor, intendedColor, lightColor, darkColor)
      setContrastColor(newColor)
    }
  }, [backgroundColor, intendedColor])

  return contrastColor
}
