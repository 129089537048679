import { useEffect } from 'react'
import fonts from '../assets/fonts.json'
import debug from '../utils/debug'

interface Props {
  Stimulsoft: any
}

const FontLoader: React.FC<Props> = ({ Stimulsoft }) => {
  useEffect(() => {
    const loadFonts = async () => {
      debug.log('Starting to load fonts...')
      const fontPromises = fonts.fontFiles.map((font: string) => {
        return new Promise<void>((resolve, reject) => {
          try {
            Stimulsoft.Base.StiFontCollection.addFontFile(
              '/fonts/' + font,
              undefined,
              undefined,
              true
            )
            resolve()
          } catch (error) {
            console.error(`Error loading font ${font}:`, error)
            reject(error)
          }
        })
      })

      await Promise.all(fontPromises)
      debug.log('All fonts loaded.')
    }

    loadFonts()
  }, [Stimulsoft])

  return null
}

export default FontLoader
