import React, { useCallback, useEffect, useState } from 'react'
import { FlexGridColumn } from '@grapecity/wijmo.react.grid'
import { DataMap } from '@grapecity/wijmo.grid'
import { AutoComplete } from '@grapecity/wijmo.input'

import { request } from './state/api'
import { useTranslation } from './services/i18n'
import { DataGrid } from './components/DataGrid'
import { Rule } from '../../api/app/rules'
import { useInstance } from './state/auth/currentInstance'
import { ApiConnectorCollectionView } from './components/DataGrid/ApiConnectorCollectionView'
import debug from './utils/debug'

export const Rules = (props: { type: string }) => {
  const instanceId = useInstance(({ gsr_inst }) => gsr_inst)
  const { t } = useTranslation()
  const [sources, setSources] = useState([])

  const classNames = Object.values(
    {
      privacy: ['personal', 'non-personal'],
      security: ['public', 'internal', 'classified'],
      change: ['yearly', 'monthly', 'daily', 'hourly'],
    }[props.type] || {}
  ).map(className => ({
    id: className,
    label: t(className),
  }))

  useEffect(() => {
    ;(async () => {
      const { data } = await request(`/sources`, { queryParameters: { gsr_inst: instanceId } })
      setSources(data)
    })()
  }, [instanceId])

  const processRowUpdate = React.useCallback(
    async (
      input: Rule,
      collectionView: ApiConnectorCollectionView,
      cancel: (reason: string) => void
    ) => {
      if (!input.id) {
        // Cannot update without pk.
        return false
      }

      try {
        const { data } = await request(
          `/rules/${props.type}Rules/${input.id}?gsr_inst=${instanceId}`,
          { method: 'put', jsonBody: input }
        )

        return !!data.rule ? data.rule : false
      } catch (e) {
        cancel(t('could-not-update-rule'))
        return false
      }
    },
    [t, props.type, instanceId]
  )

  const processRowCreation = useCallback(
    async (
      input: Rule,
      collectionView: ApiConnectorCollectionView,
      cancel: (reason: string) => void
    ) => {
      debug.log('Composing new row', input, collectionView, cancel)
      if (!input || !input.gsr_src) {
        // Cannot create rules without any source or a class name.
        return cancel(t('please-enter-a-source-for-new-rule'))
      }

      if (!input.class_name) {
        // Make sure to provide the required values.
        input.class_name = classNames[0].id
      }

      try {
        const { data: rule } = await request(`/sources/${input.gsr_src}/${props.type}Rule`, {
          method: 'post',
          jsonBody: input,
          queryParameters: {
            gsr_inst: instanceId,
          },
        })

        return rule
      } catch (error) {
        console.error(error)
        cancel(t('could-not-create-rule'))
        return false
      }
    },
    [t, props.type, instanceId, classNames]
  )

  return (
    <DataGrid
      processRowCreation={processRowCreation}
      processRowUpdate={processRowUpdate}
      endpoint={`/rules/${props.type}Rules`}
      requestOptions={{ queryParameters: { gsr_inst: instanceId } }}
      FlexGridProps={{
        allowAddNew: true,
        newRowAtTop: true,
        allowDelete: true,
      }}>
      <FlexGridColumn
        header={t('data_source')}
        binding="gsr_src"
        width="*"
        dataMap={new DataMap(sources, 'gsr_src', 'name')}
        editor={
          new AutoComplete(document.createElement('div'), {
            itemsSource: sources,
            selectedValuePath: 'gsr_src',
            displayMemberPath: 'name',
          })
        }
      />
      <FlexGridColumn
        isRequired
        width="*"
        binding="class_name"
        header={t('class-name')}
        dataMap={new DataMap(classNames, 'id', 'label')}
        editor={
          new AutoComplete(document.createElement('div'), {
            itemsSource: classNames,
            selectedValuePath: 'id',
            displayMemberPath: 'label',
          })
        }
      />
      <FlexGridColumn header={t('catalog-pattern')} binding="catalog_pattern" width="*" />
      <FlexGridColumn header={t('schema-pattern')} binding="schema_pattern" width="*" />
      <FlexGridColumn header={t('table-pattern')} binding="table_pattern" width="*" />
      <FlexGridColumn header={t('column-pattern')} binding="column_pattern" width="*" />
      <FlexGridColumn header={t('priority')} binding="gsr_seq" width="*" />
    </DataGrid>
  )
}
