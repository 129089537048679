import { copy } from '@grapecity/wijmo'
import { RestCollectionView } from '@grapecity/wijmo.rest'
import { ApiRequestOptions, request } from '../../state/api'

export type GridEventHandler = (
  item: any,
  collectionView: ApiConnectorCollectionView,
  cancel: (reason: string) => void
) => any

export class ApiConnectorCollectionView extends RestCollectionView {
  constructor(
    private _endpoint: string,
    private _requestOptions?: ApiRequestOptions,
    private _updateHandler?: GridEventHandler,
    private _createHandler?: GridEventHandler,
    private _removeHandler?: GridEventHandler,
    options?: any
  ) {
    const params = new URLSearchParams(window.location.search)
    const urlPage = params.get('page')
    const urlPageSize = params.get('pageSize')

    super({
      pageSize: urlPageSize ? parseInt(urlPageSize) : 5000,
      ...options,
    })

    // We need to implement advanced filtering in the API for this, let's do the next best thing.
    this.filterOnServer = false

    copy(this, options)
  }

  protected async getItems(): Promise<any[]> {
    try {
      let queryParams = new URLSearchParams(window.location.search)

      //   // Only update URL params if they've changed
      //   if (this.pageSize.toString() !== queryParams.get('pageSize')) {
      //     queryParams.set('pageSize', this.pageSize.toString())
      //   }
      //   if ((this.pageIndex + 1).toString() !== queryParams.get('page')) {
      //     queryParams.set('page', (this.pageIndex + 1).toString())
      //   }

      if (this.sortOnServer && this.sortDescriptions.length) {
        queryParams.set('sort_by', this.sortDescriptions[0].property)
        queryParams.set('order', this.sortDescriptions[0].ascending ? 'asc' : 'desc')
        // We currently only support sorting by single attributes.
        this._requestOptions = {
          ...this._requestOptions,
          queryParameters: {
            ...this._requestOptions?.queryParameters,
            sort_by: this.sortDescriptions[0].property,
            order: this.sortDescriptions[0].ascending ? 'asc' : 'desc',
          },
        }
      }

      if (this.pageOnServer && this.pageSize > 0) {
        queryParams.set('page', String(this.pageIndex + 1))
        queryParams.set('pageSize', String(this.pageSize))
        this._requestOptions = {
          ...this._requestOptions,
          queryParameters: {
            ...this._requestOptions?.queryParameters,
            page: this.pageIndex + 1,
            pageSize: this.pageSize,
          },
        }
      }

      // Update URL without reloading page
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`)

      const { data, recordCount } = await request(this._endpoint, this._requestOptions)

      this._totalItemCount = recordCount

      return data
    } catch (error) {
      this._raiseError(error, false)
      return []
    }
  }

  protected async addItem(item: any) {
    if (!this._createHandler) {
      return false
    }

    const updatedItem = await this._createHandler(item, this, (reason: string) =>
      this._raiseError(reason, false)
    )

    for (var key in updatedItem) {
      item[key] = updatedItem[key]
    }

    return item
  }

  protected async patchItem(item: any): Promise<any> {
    if (!this._updateHandler) {
      return false
    }

    const updatedItem = this._updateHandler(item, this, (reason: string) =>
      this._raiseError(reason, false)
    )

    for (var key in updatedItem) {
      item[key] = updatedItem[key]
    }

    return item
  }

  protected async deleteItem(item: any): Promise<any> {
    if (this._removeHandler) {
      return this._removeHandler(item, this, (reason: string) => this._raiseError(reason, false))
    }

    // No custom remove handler defined, do the next best thing and try it yourself.
    await request(this._endpoint + `/${item.id}`, { method: 'delete', ...this._requestOptions })
  }
}
