import React, { useState, useCallback, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'

import { request } from './state/api'
import { Breadcrumbs } from './settings/Breadcrumbs'
import { useTranslation } from './services/i18n'
import { FlowEntity, parseEntities } from './InformationFlows/Parser'
import debug from './utils/debug'

const Entity = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  margin: theme.spacing(1),
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}))

const Source = styled('div')(({ theme }) => ({}))

const SourceTable = styled('div')(({ theme }) => ({
  backgroundColor: '#59a9f0',
  color: '#22282e',
  padding: theme.spacing(1),
  fontWeight: 500,
}))

const SourceFields = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  display: 'flex',
  backgroundColor: '#fff',
}))

const SourceField = styled('li')(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid rgba(0,0,0,.1)',
  borderRadius: '3px',
  margin: theme.spacing(0.5),
}))

const SourceCondition = styled('div')(({ theme }) => ({
  borderTop: '1px solid rgba(0,0,0,.1)',
  padding: theme.spacing(1),
  backgroundColor: '#fff',
  borderBottom: '1px solid rgba(0,0,0,.2)',
}))

const Actions = styled('ul')(({ theme }) => ({
  padding: theme.spacing(1),
}))

const Preview = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  border: theme.spacing(1) + ' solid #c9ced4',
  backgroundColor: '#fff',
  flex: 1,
}))

const Paper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const Editor = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  border: '1px solid rgba(0,0,0,.1)',
  backgroundColor: '#e6eef5',
}))

interface InformationFlow {
  id: string
  label: string
  structure: string
  schema: string
  entity_name: string
  version: string
  deployed: boolean
  comments: string[]
}

export const InformationFlowEditor = () => {
  const { t } = useTranslation()
  const { processId: recordId } = useParams()
  const [, setIsLoading] = useState(false)
  // const [ isSaving, setIsSaving ] = useState(false)

  const [flow, setFlow] = useState<InformationFlow>()
  const [entities, setEntities] = useState<FlowEntity[]>([])

  const load = useCallback(async () => {
    setIsLoading(true)
    let flowData = (await request('/information-flows/' + recordId)).data as InformationFlow

    // Let us prepare the raw flow data a bit to make working with it much easier.
    flowData.structure = flowData.structure.trim()

    setEntities(parseEntities(flowData.structure))
    setFlow(flowData)
    setIsLoading(false)
  }, [recordId])

  // Load the requested record if and when the recordId changes.
  useEffect(() => {
    recordId && load()
  }, [recordId, load])

  debug.log(entities)

  let breadcrumbTitle = recordId
    ? t('navigation.update-resource', { name: flow?.label })
    : t('navigation.information-flows-create')

  return (
    <Paper>
      <div>
        <Breadcrumbs>
          <Link to="../">
            <Typography color="text.secondary">{t('navigation.information-flows')}</Typography>
          </Link>
          <Typography color="text.primary">{breadcrumbTitle}</Typography>
        </Breadcrumbs>
      </div>
      <Editor>
        {entities.map(entity => (
          <Entity>
            <Source>
              <SourceTable>
                {entity.source.schemaName}.{entity.source.entityName}
              </SourceTable>
              <SourceFields>
                {entity.source.columns.map(field => (
                  <SourceField>{field}</SourceField>
                ))}
              </SourceFields>
              <SourceCondition>
                <TextField
                  label="Condition"
                  variant="filled"
                  fullWidth={true}
                  value={entity.source.condition}
                />
              </SourceCondition>
            </Source>
            <Actions>
              {entity.actions.map(action => (
                <li>{action.type}</li>
              ))}
            </Actions>
          </Entity>
        ))}
        <Preview>
          <DataGrid
            disableRowSelectionOnClick
            pagination
            loading={false}
            columns={entities[0]?.source.columns.map(field => ({ field })) || []}
            rows={[]}
            components={{}}
          />
        </Preview>
      </Editor>
    </Paper>
  )
}
