import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route, useParams, useNavigate, Link, Navigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import MonitorIcon from '@mui/icons-material/Monitor'
import KeyIcon from '@mui/icons-material/Key'
import IconButton from '@mui/material/IconButton'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import UploadIcon from '@mui/icons-material/Upload'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

import { Breadcrumbs } from './Breadcrumbs'
import { useTranslation } from '../services/i18n'
import { useSnackbar } from '../components/Snackbars'
import { request } from '../state/api'
import { useClient } from '../state/auth/currentClient'
import { useUser } from '../state/auth/currentUser'
import { Account } from '../../../api/app/accounts'
import { Role } from '../../../api/app/roles'
import { AutoSuggestList } from '../components/AutoSuggestList'
import { FormButtons } from '../components/FormButtons'
import { FormTabs } from '../components/FormTabs'
import { Form, FormContent, Label, Field, Heading } from '../components/Form'
import { FullPageDataTableContainer } from '../components/DataTable'
import { Tooltip } from '@mui/material'
import debug from '../utils/debug'

export const AccountsForm = () => (
  <Routes>
    <Route path="/" element={<Navigate to="general" replace />} />
    <Route path=":tabId" element={<Content />} />
  </Routes>
)

export const Content = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const clientId = useClient(client => client.gsr_client)
  const [userId, refreshUser] = useUser(user => [user.id, user.refresh])

  const { account_id: recordId, tabId: currentTab } = useParams()
  const { showSnackbar } = useSnackbar()
  const [, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [editPassword, setEditPassword] = useState<Boolean>(false)

  // The account data
  const [name, setName] = useState<string>('')
  const [nameIsValid, setNameIsValid] = useState<boolean>(false)
  const [openNameTooltip, setOpenNameTooltip] = useState<boolean>(false)
  const [nameErrorMessage, setNameErrorMessage] = useState(t('name-empty'))
  const [email, setEmail] = useState<string>('')
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false)
  const [openEmailTooltip, setOpenEmailTooltip] = useState<boolean>(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState(t('email-empty'))
  const [perspective, setPerspective] = useState('business')
  const [sAvatar, setAvatar] = useState('')
  const [uploadedAvatar, setUploadedAvatar] = useState('')
  const [avatarDialogOpened, setAvatarDialogOpened] = useState(false)
  const [cropper, setCropper] = useState<any>()
  const [preferred_language, setPreferredLanguage] = useState('')
  const [roles, setRoles] = useState<Role[]>([])
  const [password, setPassword] = useState<string>('')
  const [hide_hints, setHideHints] = useState<boolean>(false)

  const load = useCallback(async () => {
    setIsLoading(true)
    const user = (await request('/accounts/' + recordId)).data as Account
    setName(user.name || '')
    setEmail(user.email || '')
    setAvatar(user.avatar || '')
    setRoles(user.roles || [])
    setPreferredLanguage(user.preferred_language)
    setPerspective(user.perspective)
    setHideHints(user.hide_hints)
    setIsLoading(false)
  }, [recordId])

  useEffect(() => {
    recordId && load()
  }, [recordId, load])

  const validateNameAndSetError = (name: string) => {
    const nameRegex = /^[\p{Letter}\p{Mark}\p{Number}'., -]{1,50}$/u
    const passesRegexTest = nameRegex.test(name)

    setName(name)
    if (name === '') {
      setNameIsValid(false)
      setNameErrorMessage(t('name-empty'))
      return true
    } else if (!passesRegexTest) {
      setNameIsValid(false)
      setNameErrorMessage(t('name-invalid-format'))
      return true
    } else {
      setNameIsValid(true)
      setNameErrorMessage('')
      return false
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value
    setName(newName)
    validateNameAndSetError(newName)
  }

  const validateEmailAndSetError = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const passesRegexTest = emailRegex.test(email)

    if (email.trim() === '') {
      setEmailIsValid(false)
      setEmailErrorMessage(t('email-empty'))
      return true
    } else if (!passesRegexTest) {
      setEmailIsValid(false)
      setEmailErrorMessage(t('email-invalid-format'))
      return true
    } else {
      setEmailIsValid(true)
      setEmailErrorMessage('')
      return false
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    validateEmailAndSetError(newEmail)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const nameHasError = validateNameAndSetError(name)
    const emailHasError = validateEmailAndSetError(email)

    const hasErrors = nameHasError || emailHasError

    debug.group('Form submitted with these values')
    debug.log('➡️ name :', name)
    debug.log('➡️ valid name: ', nameIsValid)
    debug.log('➡️ email :', email)
    debug.log('➡️ valid email: ', !emailHasError)
    debug.log('nameHasError: ', nameHasError)
    debug.log('emailHasError: ', emailHasError)
    debug.log('➡️ hasErrors: ', hasErrors)
    debug.log('➡️ sAvatar: ', sAvatar)
    debug.log('➡️ password: ', password)
    debug.log('➡️ perspective: ', perspective)
    debug.log('➡️ preferred language: ', preferred_language)
    debug.log('➡️ roles: ', roles)
    debug.log('➡️ hide_hints: ', hide_hints)

    if (hasErrors) {
      debug.log('🛑 stopping save')
      // If input fields are invalid. Prevent exit.

      if (nameHasError) setOpenNameTooltip(true)
      if (emailHasError) setOpenEmailTooltip(true)

      setIsSaving(false)
      return
    }
    debug.log('💾 saving')
    setIsSaving(true)

    const jsonBody = {
      name,
      email,
      avatar: sAvatar,
      roles: roles.map(role => role.id),
      ...(editPassword && { password }),
      preferred_language,
      perspective,
      hide_hints,
    }

    try {
      if (!recordId) {
        await request(`/clients/${clientId}/accounts`, { method: 'post', jsonBody })
      } else {
        await request(`/accounts/${recordId}`, { method: 'put', jsonBody })
      }

      if (recordId === userId) {
        // We just updated our own user, refresh it in the store so that the changes are reflected
        // in the rest of the UI immediately.
        // @todo this provokes an infinite loop somewhere.. gotta find it before activating this.
        await refreshUser()
      }

      showSnackbar({ message: t('save-success'), severity: 'success' })
      navigate('../../')
    } catch (e) {
      showSnackbar({ message: t('save-error'), severity: 'error' })
    }

    setIsSaving(false)

    return false
  }

  const scaleDownImage = async (
    dataUri: string,
    maxWidth: number,
    maxHeight: number,
    quality = 1
  ): Promise<string> => {
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
    const context = canvas.getContext('2d')

    if (!context) return ''

    img.src = dataUri

    await new Promise(resolve => (img.onload = resolve))

    context.drawImage(img, 0, 0)

    let { width, height } = img

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
    } else if (height > maxHeight) {
      width *= maxHeight / height
      height = maxHeight
    }

    canvas.width = width
    canvas.height = height

    context.drawImage(img, 0, 0, width, height)

    return canvas.toDataURL('image/jpeg', quality)
  }

  const openAvatarDialog = () => {
    setAvatarDialogOpened(true)
  }

  const handleAvatarChange = async () => {
    if (cropper && cropper.getCroppedCanvas()) {
      setAvatar(await scaleDownImage(cropper.getCroppedCanvas().toDataURL(), 300, 300, 0.65))
    }

    setAvatarDialogOpened(false)
    // Intentionally not clearing this here so the user can come back to fix his cropping before saving.
    // setUploadedAvatar('')
  }

  const handleCapture = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !e.currentTarget.files) {
      return
    }

    const fileReader = new FileReader()

    fileReader.readAsDataURL(e.currentTarget.files[0])
    fileReader.onload = async e =>
      e?.target?.result &&
      setUploadedAvatar(await scaleDownImage(e.target.result.toString(), 1500, 1500))
  }

  return (
    <FullPageDataTableContainer>
      <Dialog
        fullWidth
        maxWidth="md"
        open={avatarDialogOpened}
        onClose={e => setAvatarDialogOpened(false)}
        aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">{t('profile-change-avatar-dialog-title')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            sx={{
              width: '100%',
              padding: '1px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'divider',
              '& .cropper-view-box': {
                outlineColor: theme => theme.palette.primary.main,
              },
              '& .cropper-view-box, & .cropper-face': {
                borderRadius: '100%',
              },
            }}>
            <Cropper
              style={{ height: 400, width: '100%' }}
              src={uploadedAvatar}
              viewMode={1}
              center={false}
              background={false}
              responsive={true}
              dragMode="move"
              autoCropArea={1}
              aspectRatio={800 / 800}
              cropBoxMovable={false}
              cropBoxResizable={false}
              checkOrientation={true}
              onInitialized={instance => setCropper(instance)}
              guides={false}
            />
          </Box>
          <div>
            <IconButton onClick={() => cropper && cropper.rotate(-90)}>
              <RotateLeftIcon />
            </IconButton>
            <IconButton onClick={() => cropper && cropper.rotate(90)}>
              <RotateRightIcon />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions>
          <label style={{ marginRight: 'auto' }} htmlFor="contained-button-file">
            <input
              hidden
              onChange={handleCapture}
              accept="image/*"
              id="contained-button-file"
              type="file"
            />
            <Button variant="outlined" startIcon={<UploadIcon />} component="span">
              {t('profile-change-avatar-dialog-upload')}
            </Button>
          </label>
          <Button onClick={() => setAvatarDialogOpened(false)}>{t('cancel')}</Button>
          <Button variant="contained" onClick={handleAvatarChange} autoFocus>
            {t('profile-change-avatar-dialog-confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Breadcrumbs>
        <Link to="../../">
          <Typography color="text.secondary">{t('navigation.account-management')}</Typography>
        </Link>
        <Typography color="text.primary">
          {recordId ? t('navigation.update-resource', { name }) : t('navigation.accounts-create')}
        </Typography>
      </Breadcrumbs>
      <Form onSubmit={e => handleSubmit(e)}>
        <FormTabs
          tabs={[
            { id: 'general', label: t('general-information'), icon: <TextSnippetIcon /> },
            { id: 'ui', label: t('ui'), icon: <MonitorIcon /> },
            { id: 'security', label: t('security'), icon: <KeyIcon /> },
          ]}
        />

        <FormContent hidden={currentTab !== 'general'}>
          <Label htmlFor="name">{t('name')}</Label>
          <Field>
            <Tooltip title={nameErrorMessage} placement="right" open={openNameTooltip} arrow>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                autoFocus
                id="name"
                type="text"
                hiddenLabel
                value={name}
                margin="dense"
                onChange={handleNameChange}
                error={!nameIsValid}
                // helperText={!nameIsValid ? nameErrorMessage : ''}
              />
            </Tooltip>
          </Field>

          <Label htmlFor="email">{t('email-address')}</Label>
          <Field>
            <Tooltip title={emailErrorMessage} placement="right" open={openEmailTooltip} arrow>
              <TextField
                id="email"
                hiddenLabel
                type="text"
                value={email}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={handleEmailChange}
                error={!emailIsValid}
                // helperText={!emailIsValid ? emailErrorMessage : ''}
              />
            </Tooltip>
          </Field>

          <Label htmlFor="avatar">{t('avatar')}</Label>
          <Field>
            <div>
              <Avatar src={sAvatar} sx={{ height: 150, width: 150, marginBottom: 1 }}>
                {name
                  ?.match(/(^\S\S?|\s\S)?/g)
                  ?.map(v => v.trim())
                  ?.join('')
                  ?.match(/(^\S|\S$)?/g)
                  ?.join('')
                  ?.toLocaleUpperCase()}
              </Avatar>
            </div>
            {sAvatar && <Button onClick={() => setAvatar('')}>{t('profile-reset-avatar')}</Button>}
            <Button variant="outlined" onClick={openAvatarDialog}>
              {t('profile-change-avatar')}
            </Button>
          </Field>
        </FormContent>

        <FormContent hidden={currentTab !== 'ui'}>
          <Label htmlFor="language">{t('preferred-language')}</Label>
          <Field>
            <TextField
              select
              hiddenLabel
              id="language"
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              value={preferred_language}
              onChange={({ target }) => setPreferredLanguage(target.value)}>
              <MenuItem value="">{t('detect-automatically')}</MenuItem>
              <MenuItem value="en-US">{t('en-US')}</MenuItem>
            </TextField>
          </Field>
          <Label htmlFor="perspective">{t('interface-perspective')}</Label>
          <Field>
            <TextField
              select
              hiddenLabel
              margin="dense"
              id="perspective"
              fullWidth
              size="small"
              variant="outlined"
              value={perspective}
              onChange={({ target }) => setPerspective(target.value)}>
              <MenuItem value="business">{t('business-user')}</MenuItem>
              <MenuItem value="technical">{t('technical-user')}</MenuItem>
            </TextField>
          </Field>
          <Label htmlFor="hints">{t('interface-hide-hints')}</Label>
          <Field>
            <Checkbox
              id="hints"
              checked={hide_hints}
              onChange={({ target }) => setHideHints(target.checked)}
            />
          </Field>
        </FormContent>

        <FormContent hidden={currentTab !== 'security'}>
          <Heading variant="subtitle2" mt={1} mb={2}>
            {t('authentication')}
          </Heading>

          <Label htmlFor="auth-schema">{t('authentication-schema')}</Label>
          <Field>
            <TextField
              select
              id="auth-schema"
              hiddenLabel
              margin="dense"
              value="password"
              fullWidth
              size="small"
              variant="outlined">
              <MenuItem value="password">{t('password-authentication')}</MenuItem>
            </TextField>
          </Field>

          {!recordId && (
            <React.Fragment>
              <Label />
              <Field>
                <FormControlLabel
                  control={
                    <Switch defaultChecked onChange={e => setEditPassword(!e.target.checked)} />
                  }
                  label={t('send-email-invite')}
                />
              </Field>
            </React.Fragment>
          )}

          {!editPassword && (
            <React.Fragment>
              <Label />
              <Field>
                <Button size="small" variant="text" onClick={e => setEditPassword(true)}>
                  {t('set-password')}
                </Button>
              </Field>
            </React.Fragment>
          )}

          {editPassword && (
            <React.Fragment>
              <Label htmlFor="password">{t('password')}</Label>
              <Field>
                <TextField
                  hiddenLabel
                  margin="dense"
                  type="password"
                  value={password}
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={e => setPassword(e.target.value)}
                />
              </Field>
            </React.Fragment>
          )}

          <Heading variant="subtitle2" mt={4} mb={2}>
            {t('roles')}
          </Heading>
          <Field md={12}>
            <AutoSuggestList<Role>
              items={roles}
              endpoint="/roles"
              getItemKey={role => role.id}
              getItemLabel={role => role.name}
              onChange={roles => setRoles(roles)}
              removeTitle={t('remove-role-from-account')}
              inputPlaceholder={t('assign-new-role-to-account')}
            />
          </Field>
        </FormContent>

        {currentTab === 'security' && (
          <>
            <Alert severity="info">{t('permissions-hint-delay')}</Alert>
          </>
        )}

        <FormButtons isSaving={isSaving} />
      </Form>
    </FullPageDataTableContainer>
  )
}
