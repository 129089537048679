import chalk from 'chalk'
import { configStore } from '../state/config'

const debug = {
  log: (message: any, ...args: any[]) => {
    if (configStore.getState().environment === 'development') {
      console.log(message, ...args)
    }
  },
  warn: (message: any, ...args: any[]) => {
    if (configStore.getState().environment === 'development') {
      console.warn(message, ...args)
    }
  },
  error: (message: any, ...args: any[]) => {
    if (configStore.getState().environment === 'development') {
      console.error(message, ...args)
    }
  },
  group: (message: any) => {
    if (configStore.getState().environment === 'development') {
      console.group(message)
    }
  },
}

export const colorizeMethod = (method: string = 'GET') => {
  switch (method.toUpperCase()) {
    case 'GET':
      return chalk.yellow(method)
    case 'POST':
      return chalk.green(method)
    case 'PUT':
      return chalk.blue(method)
    case 'DELETE':
      return chalk.red(method)
    case 'PATCH':
      return chalk.magenta(method)
    default:
      return chalk.white(method)
  }
}

export default debug
