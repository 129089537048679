import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { default as MuiMenu } from '@mui/material/Menu'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import { default as ConceptIcon } from '@mui/icons-material/Category'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AssessmentIcon from '@mui/icons-material/Assessment'
import StorageIcon from '@mui/icons-material/Storage'
import { default as AccessTokensIcon } from '@mui/icons-material/DataObject'
import { default as LogoutIcon } from '@mui/icons-material/ExitToApp'
import PeopleIcon from '@mui/icons-material/People'
import PaymentIcon from '@mui/icons-material/Payment'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import LayersIcon from '@mui/icons-material/Layers'
import { default as ClientsIcon } from '@mui/icons-material/Ballot'
import SettingsIcon from '@mui/icons-material/Settings'
import { default as DataModelIcon } from '@mui/icons-material/DeviceHubOutlined'
import HomeIcon from '@mui/icons-material/Home'
import DashboardIcon from '@mui/icons-material/Dashboard'

import { useUser } from '../../state/auth/currentUser'
import { usePermissions } from '../../state/auth/currentPermissions'
import { useTranslation } from '../../services/i18n'
import { useContrastColor } from '../../hooks/useContrastColor'

const Link = styled(NavLink)(({ theme }) => ({
  height: theme.spacing(6),
  '&.active': {
    '& .MuiListItemIcon-root': {
      color: theme.palette.secondary.main + ' !important',
    },
    borderColor: theme.palette.secondary.main + ' !important',
  },
}))

export interface NavigationLinkProps {
  onClick?: any
  to: String
  end?: Boolean
  children: React.ReactNode
  className?: string
  noHighlight?: boolean
  dense?: boolean
  component?: React.ComponentType<any>
}

export const NavigationLink = (props: NavigationLinkProps) => {
  const { pathname } = useLocation()

  const Component = props.component ? props.component : ListItem

  return (
    <Component
      onClick={props.onClick}
      dense={props.dense}
      className={props.className}
      {...{
        component: Link,
        to: props.to,
        button: true,
        end: props.end,
      }}
      selected={props.to === pathname && !props.noHighlight}>
      {props.children}
    </Component>
  )
}

export const SubNavigationLink = (props: any) => {
  return <NavigationLink dense {...props} sx={{ paddingLeft: 4, fontSize: '100%' }} />
}

export const Menu = () => {
  const dashboardAllowed = usePermissions(p => p.has('instances'))
  // const informationProcessesAllowed = usePermissions(p => p.has('information-processes'))
  const sourcesAllowed = usePermissions(p => p.has('sources'))
  const deploymentTargetsAllowed = usePermissions(p => p.has('deployment-targets'))
  const instanceOptionsAllowed = usePermissions(p => p.has('instances'))
  const dashboardsAllowed = usePermissions(p => p.has('instances'))
  const dataModelAllowed = usePermissions(p => p.has('data-model'))

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <List sx={{ flex: 1 }}>
        {dashboardAllowed && (
          <NavigationLink to="">
            <Tooltip title={t('navigation.overview')} placement="right" arrow>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
          </NavigationLink>
        )}

        {sourcesAllowed && (
          <NavigationLink to="/sources">
            <Tooltip title={t('navigation.sources')} placement="right" arrow>
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
            </Tooltip>
          </NavigationLink>
        )}

        {instanceOptionsAllowed && (
          <NavigationLink to="/influence">
            <Tooltip title={t('influence')} placement="right" arrow>
              <ListItemIcon>
                <ConceptIcon />
              </ListItemIcon>
            </Tooltip>
          </NavigationLink>
        )}

        {/*informationProcessesAllowed && 
                <NavigationLink to="/information-flows">
                    <Tooltip title={t('navigation.information-flows')} placement="right" arrow>
                        <ListItemIcon><DashboardIcon/></ListItemIcon>
                    </Tooltip>
                </NavigationLink>
            */}

        {deploymentTargetsAllowed && (
          <NavigationLink to="/targets">
            <Tooltip title={t('navigation.targets')} placement="right" arrow>
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
            </Tooltip>
          </NavigationLink>
        )}

        {dataModelAllowed && (
          <NavigationLink to="/model/history" noHighlight>
            <Tooltip title={t('navigation-data-model')} placement="right" arrow>
              <ListItemIcon>
                <DataModelIcon />
              </ListItemIcon>
            </Tooltip>
          </NavigationLink>
        )}

        {dashboardsAllowed && (
          <NavigationLink to="/reports">
            <Tooltip title={t('navigation.reports')} placement="right" arrow>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
            </Tooltip>
          </NavigationLink>
        )}

        {instanceOptionsAllowed && (
          <NavigationLink to="/instance-options">
            <Tooltip title={t('navigation.options')} placement="right" arrow>
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
            </Tooltip>
          </NavigationLink>
        )}

        {/*<NavigationLink to="/documentation">
                <ListItemIcon><AssignmentIcon/></ListItemIcon>
                <ListItemText primary={"Documentation"} />
            </NavigationLink>*/}
      </List>
    </React.Fragment>
  )
}

const AuthContainer = styled('div')(() => ({
  //marginLeft: 'auto',
}))

export const AuthMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClose = () => setAnchorEl(null)
  const userName = useUser(user => user.name)
  const avatar = useUser(user => user.avatar)

  const theme = useTheme()
  const primaryColor = theme.palette.primary.main
  const intendedColor = '#FDEC4F'
  const backgroundColor = useContrastColor(primaryColor, intendedColor)

  // @ts-ignore
  let initials = userName
    .match(/(^\S\S?|\s\S)?/g)
    .map(v => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toLocaleUpperCase()

  const { t } = useTranslation()

  const apiKeysAllowed = usePermissions(p => p.has('api-keys.own.list'))

  return (
    <AuthContainer>
      <Button
        sx={{
          '& .MuiButton-endIcon': { marginLeft: 0 },
        }}
        endIcon={
          <KeyboardArrowDownOutlinedIcon sx={{ marginLeft: 0, width: '1em', opacity: 0.4 }} />
        }
        title={userName}
        variant="text"
        color="inherit"
        aria-label="account of current user"
        aria-controls="menu-authentication"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}>
        <Avatar
          src={avatar}
          sx={{
            height: 30,
            width: 30,
            fontSize: '90%',
            bgcolor: backgroundColor,
            color: useContrastColor(backgroundColor, '#000000'),
          }}>
          {initials}
        </Avatar>
      </Button>
      <MuiMenu
        MenuListProps={{ dense: true }}
        id="menu-authentication"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <NavigationLink to="/settings/profile" onClick={handleClose}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary={t('navigation.profile')} />
        </NavigationLink>

        {apiKeysAllowed && (
          <NavigationLink to="/settings/api-keys" onClick={handleClose}>
            <ListItemIcon>
              <AccessTokensIcon />
            </ListItemIcon>
            <ListItemText primary={t('api-keys')} />
          </NavigationLink>
        )}

        <NavigationLink to="/settings/logout" onClick={handleClose}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t('navigation.logout')} />
        </NavigationLink>
      </MuiMenu>
    </AuthContainer>
  )
}

const SettingsContainer = styled('div')(({ theme }) => ({
  //marginLeft: theme.spacing(1),
}))

const SettingsMenuElement = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderTopRightRadius: 0,
  },
}))

export const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClose = () => setAnchorEl(null)
  const theme = useTheme()
  const backgroundColor = theme.palette.primary.main

  const globalOptionsAllowed = usePermissions(p => p.has('global-options'))
  const accountsAllowed = usePermissions(p => p.has('accounts'))
  const rolesAllowed = usePermissions(p => p.has('roles'))
  const instancesAllowed = usePermissions(p => p.has('instances'))
  const billingAllowed = usePermissions(p => p.has('billing'))
  const clientsAllowed = usePermissions(p => p.has('clients'))

  const { t } = useTranslation()

  return (
    <SettingsContainer>
      <IconButton
        aria-label="Settings"
        aria-controls="menu-settings"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}>
        {/*t('navigation.settings')*/}
        <SettingsIcon
          sx={{
            width: '1em',
            color: useContrastColor(backgroundColor, '#FFFFFF'),
            opacity: 0.7,
            '&:hover': { opacity: 1 },
          }}
        />
      </IconButton>

      <SettingsMenuElement
        MenuListProps={{ dense: true }}
        id="menu-settings"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {globalOptionsAllowed && (
          <NavigationLink onClick={handleClose} to="/settings/options">
            <ListItemIcon>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.global-options')} />
          </NavigationLink>
        )}

        {accountsAllowed && (
          <NavigationLink onClick={handleClose} to="/settings/accounts">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.account-management')} />
          </NavigationLink>
        )}

        {rolesAllowed && (
          <NavigationLink onClick={handleClose} to="/settings/roles">
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.roles')} />
          </NavigationLink>
        )}

        {instancesAllowed && (
          <NavigationLink onClick={handleClose} to="/settings/instances">
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.instances')} />
          </NavigationLink>
        )}

        {billingAllowed && (
          <NavigationLink onClick={handleClose} to="/settings/billing">
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.billing')} />
          </NavigationLink>
        )}

        {clientsAllowed && (
          <NavigationLink onClick={handleClose} to="/settings/clients">
            <ListItemIcon>
              <ClientsIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.clients')} />
          </NavigationLink>
        )}
      </SettingsMenuElement>
    </SettingsContainer>
  )
}
