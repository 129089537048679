import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

// Declare the global configuration variable
declare var flowBiConfig: ConfigStore

// Define the ConfigStore type
type ConfigStore = {
  revision: string
  version: string
  buildTime: string
  apiUrl: string
  reportsUrl: string
  environment: string
  toggleEnvironment: () => void
}

// Default configuration values
const DEFAULTS = {
  REVISION: '00000000',
  BUILD_TIME: new Date().toLocaleString(),
  API_URL: 'http://localhost',
  REPORTS_URL: 'http://localhost',
  ENVIRONMENT: 'development',
}

// Create the Zustand store
export const configStore = create<ConfigStore>()(
  devtools(
    (set, get) => ({
      revision: DEFAULTS.REVISION,
      version: 'v.' + DEFAULTS.REVISION,
      buildTime: DEFAULTS.BUILD_TIME,
      apiUrl: DEFAULTS.API_URL,
      reportsUrl: DEFAULTS.REPORTS_URL,
      environment: DEFAULTS.ENVIRONMENT,
      toggleEnvironment: () => {
        set(state => ({
          environment: state.environment === 'development' ? 'production' : 'development',
        }))
      },
    }),
    { enabled: true, name: 'configStore' }
  )
)
export const useConfig = configStore

// Function to set default values if placeholders are found
// code reference - look for flowBiConfig in index.html
const setDefaultValues = (config: ConfigStore) => {
  const {
    revision = DEFAULTS.REVISION,
    buildTime = DEFAULTS.BUILD_TIME,
    apiUrl = DEFAULTS.API_URL,
    reportsUrl = DEFAULTS.REPORTS_URL,
    environment = DEFAULTS.ENVIRONMENT,
  } = config

  const resolvedRevision = revision === '__REVISION__' ? DEFAULTS.REVISION : revision

  return {
    revision: resolvedRevision,
    apiUrl: apiUrl === '__API_URL__' ? DEFAULTS.API_URL : apiUrl,
    reportsUrl: reportsUrl === '__REPORTS_URL__' ? DEFAULTS.REPORTS_URL : reportsUrl,
    environment: environment === '__ENVIRONMENT__' ? DEFAULTS.ENVIRONMENT : environment,
    buildTime:
      buildTime === '__BUILD_TIME__'
        ? DEFAULTS.BUILD_TIME
        : new Date(Number(buildTime) * 1000).toLocaleString(),
    version: 'v.' + resolvedRevision.substring(0, 10),
  }
}

// Apply the configuration if available
if (typeof flowBiConfig !== 'undefined') {
  configStore.setState(setDefaultValues(flowBiConfig))
} else {
  console.error('Injected configuration is missing.')
  configStore.setState(setDefaultValues({} as ConfigStore))
}
