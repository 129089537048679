import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { useContrastColor } from '../hooks/useContrastColor'

export type TTab = {
  icon?: React.ReactElement
  label?: string
  id?: string
}

/**
 * A component that renders a set of tabs with icons and labels using Material-UI Tabs component.
 * Each tab is linked to a specific route and updates the URL when selected.
 *
 * @component
 * @param {Object} props - The component props
 * @param {TTab[]} props.tabs - An array of tab objects, where each tab has an id, icon, and label
 *
 * @example
 * const tabs = [
 *   { id: 'tab1', icon: <HomeIcon />, label: 'Home' },
 *   { id: 'tab2', icon: <SettingsIcon />, label: 'Settings' }
 * ];
 *
 * <FormTabs tabs={tabs} />
 */
export const FormTabs = (props: { tabs: TTab[] }) => {
  const { tabId } = useParams()
  const [currentTab, setCurrentTab] = useState(props.tabs.map(t => t.id).indexOf(tabId || ''))
  const primaryColor = useTheme().palette.primary.main
  const selectedTab = useContrastColor('#FFFFFF', primaryColor)

  useEffect(() => {
    setCurrentTab(props.tabs.map(t => t.id).indexOf(tabId || ''))
  }, [props.tabs, tabId])

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={currentTab}
        sx={{ minHeight: 'auto' }}
        indicatorColor="secondary"
        onChange={(e, value) => setCurrentTab(value)}>
        {props.tabs.map(tab => (
          <Tab
            key={tab.id}
            sx={{
              minHeight: 'auto',
              textTransform: 'none',
              '&.Mui-selected': {
                color: selectedTab,
              },
            }}
            id={tab.id}
            icon={tab.icon}
            iconPosition="start"
            label={tab.label}
            component={Link}
            to={`../${tab.id}`}
          />
        ))}
      </Tabs>
    </Box>
  )
}
