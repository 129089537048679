export const getContrastColor = (
  backgroundColor: string,
  intendedColor: string,
  lightColor = '#FFFFFF',
  darkColor = '#000000'
) => {
  const getRGB = (color: string) => {
    if (!color?.startsWith('#')) return null
    const hex = color.replace('#', '')
    return {
      r: parseInt(hex.substr(0, 2), 16),
      g: parseInt(hex.substr(2, 2), 16),
      b: parseInt(hex.substr(4, 2), 16),
    }
  }

  const getLuminance = (rgb: any) => {
    if (!rgb) return 0
    const [r, g, b] = [rgb.r / 255, rgb.g / 255, rgb.b / 255]
    const toLinear = (c: number) => (c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4))
    return 0.2126 * toLinear(r) + 0.7152 * toLinear(g) + 0.0722 * toLinear(b)
  }

  const getContrastRatio = (l1: number, l2: number) => {
    const lighter = Math.max(l1, l2)
    const darker = Math.min(l1, l2)
    return (lighter + 0.05) / (darker + 0.05)
  }

  const bg = getRGB(backgroundColor)
  const intended = getRGB(intendedColor)

  if (!bg || !intended) return darkColor

  const bgLuminance = getLuminance(bg)
  const intendedLuminance = getLuminance(intended)
  const contrastRatio = getContrastRatio(bgLuminance, intendedLuminance)

  // Check if intended color is light (luminance > 0.5)
  const isIntendedLight = intendedLuminance > 0.5

  // If contrast is poor but intended color is light, prefer it over white
  if (contrastRatio < 3) {
    if (isIntendedLight && bgLuminance < 0.5) {
      return intendedColor
    }
    return bgLuminance > 0.5 ? darkColor : lightColor
  }

  return intendedColor
}
