import { useCallback, useEffect, useRef, useState } from 'react'
import { FlexGridColumn } from '@grapecity/wijmo.react.grid'
import { DataMap } from '@grapecity/wijmo.grid'
import { AutoComplete } from '@grapecity/wijmo.input'
import { useInstance } from './state/auth/currentInstance'
import { DataGrid } from './components/DataGrid'
import { request } from './state/api'
import { Concept } from '../../api/app/concepts'
import { useTranslation } from './services/i18n'

export const Concepts = () => {
  const { t } = useTranslation()
  const instanceId = useInstance(instance => instance.gsr_inst)
  const params = new URLSearchParams(window.location.search)
  const pageSize = params.get('pageSize')

  const processRowUpdate = useCallback(async (input: Concept) => {
    await request('/concepts', { method: 'put', jsonBody: input })
    return input
  }, [])

  const gridInstance = useRef<any>()

  const [sourcesMap, setSourcesMap] = useState<any>()

  const fetchSources = useCallback(async () => {
    const { data: sources } = await request('/sources')
    setSourcesMap(new DataMap(sources, 'gsr_src', 'name'))
  }, [])

  useEffect(() => {
    fetchSources()
  }, [fetchSources])

  const scopes = [
    { id: 'main', label: t('concepts-scope-main') },
    { id: 'dependent', label: t('concepts-scope-dependent') },
  ]

  return (
    <DataGrid
      endpoint="/concepts"
      processRowUpdate={processRowUpdate}
      requestOptions={{ queryParameters: { instanceId } }}
      FlexGridProps={{
        allowAddNew: false,
        initialized: (grid: any) => (gridInstance.current = grid),
        pageSize: pageSize,
      }}>
      <FlexGridColumn
        header={t('data_source')}
        binding="gsr_src"
        width="*"
        isReadOnly={true}
        dataMap={sourcesMap}
      />
      <FlexGridColumn
        header={t('catalog-name')}
        binding="catalog_name"
        width="*"
        isReadOnly={true}
      />
      <FlexGridColumn header={t('schema-name')} binding="schema_name" width="*" isReadOnly={true} />
      <FlexGridColumn header={t('table-name')} binding="table_name" width="*" isReadOnly={true} />
      <FlexGridColumn header={t('concept')} binding="concept" width="*" isReadOnly={false} />
      <FlexGridColumn
        isRequired
        width="*"
        binding="scope"
        header={t('scope')}
        dataMap={new DataMap(scopes, 'id', 'label')}
        editor={
          new AutoComplete(document.createElement('div'), {
            itemsSource: scopes,
            selectedValuePath: 'id',
            displayMemberPath: 'label',
          })
        }
      />
      <FlexGridColumn
        header={t('reference-data')}
        binding="is_reference_data"
        width="*"
        isReadOnly={false}
      />
      <FlexGridColumn
        header={t('non-historized-data')}
        binding="is_non_historized_data"
        width="*"
        isReadOnly={false}
      />
    </DataGrid>
  )
}
