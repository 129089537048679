import React from 'react'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'

import { AutoSuggestList } from './AutoSuggestList'
import { ResourceRole } from '../../../api/app/roles'
import { useTranslation } from '../services/i18n'
import debug from '../utils/debug'

export interface IRoleAutoSuggestList {
  roles: ResourceRole[]
  setRoles: (roles: ResourceRole[]) => void
}

export const RoleAutoSuggestList = (props: IRoleAutoSuggestList) => {
  const { t } = useTranslation()

  debug.log(props.roles)

  const renderActions = (role: ResourceRole) => {
    let icon = <CheckIcon />
    let label = t('access-granted')
    let color: 'success' | 'error' = 'success'

    if (!props.roles.find(r => r.id === role.id && !!r?.accessControl?.granted)) {
      icon = <UnpublishedIcon />
      label = t('access-denied')
      color = 'error'
    }

    // Toggle the value of the permission (granted/denied) on click.
    const handleClick = () =>
      props.setRoles(
        props.roles.map(r => ({
          ...r,
          ...(r.id === role.id && { accessControl: { granted: !r.accessControl.granted } }),
        }))
      )

    return (
      <Chip
        onClick={handleClick}
        variant="outlined"
        color={color}
        size="small"
        icon={icon}
        label={label}
      />
    )
  }

  return (
    <AutoSuggestList<ResourceRole>
      items={props.roles}
      endpoint="/roles"
      getItemKey={role => role.id}
      getItemLabel={role => role.name}
      onChange={roles =>
        props.setRoles(
          roles.map(r =>
            // Turn results from /roles into something that aligns with ResourceRole.
            ({ ...r, accessControl: r.accessControl || { granted: false } })
          )
        )
      }
      inputPlaceholder={t('create-new-role-assignment')}
      removeTitle={t('remove-role-assignment')}
      renderActions={renderActions}
    />
  )
}
