import { useState, MouseEvent, KeyboardEvent } from 'react'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { instanceStore } from '../../state/auth/currentInstance'
import { clientStore } from '../../state/auth/currentClient'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/material/ClickAwayListener'

export default function MenuUUID() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    handleClose()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      setAnchorEl(event.currentTarget)
    }
  }

  const content = [
    {
      value: instanceStore.getState().gsr_inst,
      label: 'instance',
    },
    {
      value: clientStore.getState().gsr_client,
      label: 'client',
    },
  ]

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <>
        <button
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          aria-controls={open ? 'copy-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open}
          style={{
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            padding: 0,
            display: 'flex',
          }}>
          <ListItemIcon sx={{ color: 'gray' }}>
            <ContentCopy fontSize="small" aria-label="Copy values menu" />
          </ListItemIcon>
        </button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <Paper sx={{ width: 500, maxWidth: '100%' }}>
            <MenuList>
              {content.map(c => (
                <MenuItem key={c.label} onClick={() => handleCopyToClipboard(c.value!)}>
                  <ListItemIcon>
                    <ContentCopy fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ fontSize: '10px' }}>{c.value}</ListItemText>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {c.label}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popover>
      </>
    </ClickAwayListener>
  )
}
